//primary color pallette

//primary
$blue: #00a0d2;
$green: #00c389;
$magenta: #c110a0;

//secondary
$wtwGray: #63666a;
$violet: #7f35b2;

//functional colors - links and primary CTA
$software-blue: #007fa7;

//semantic colors - buttons, alerts, labels
$software-success: #298544;
$software-info: #069;
$software-warning: #c25400;
$software-danger: #c00;

//grays
$white:	#fff;
$software-gray-1:	#f4f4f4;
$software-gray-2:	#ecedee;
$software-gray-3:	#e4e4e4;
$software-gray-4:	#d8d8d8;
$software-gray-5:	#c5c5c5;
$software-gray-6:	#979797;
$software-gray-7:	#707070;
$software-gray-8:	#585858;
$software-gray-9:	#444;
$black:	#000;

//enhanced branding
$primary-color: $violet;
$link-color: $software-blue;
