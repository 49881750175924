@import "autoload/autoload";
// @import "variables/variables";
// @import "mixins/mixins";
// @import "base";
//@import "components/components";
//@import "pages/pages";
//@import "sections/sections";
@import "sections/onetrust-banner";
@import "_colors";
@import "../../node_modules/@ng-select/ng-select/themes/default.theme.css";

@import "variables/settings";

/*for local dev only - hide persona bar and preview bar*/

// #Body {
//     margin-left: 0 !important;
//     overflow: auto !important;
// }
// iframe#personaBar-iframe {
//     display: none;
// }
// #preview-bar {
//     display: none !important;
// }
#Body {
    div.scw-wrapper {
        display: none;
    }

    &.dnnEditState {
        div.scw-wrapper {
            display: flex;
        }
    }
}

//to accomodate fixed header on mobile
@include media-breakpoint-down(sm) {
    #Body, #main-content {
        margin-top: 75px;
    }
}

// global theme - all modules styles here//
:root {
    --primary-color: #{$primary-color};
    --link-color: #{$link-color};
}

//theme mods//
.col-md {
    padding-top: 15px;

    &:last-child {
        margin-bottom: 0;
    }
}

// @include media-breakpoint-up(md) {
//     .col-md {
//         padding-top: 15px;
//     }
// }

// @media only screen and (max-width: 48em) {
//     [class^="col-md"]+[class^="col-md"] {
//         margin-top: 0;
//     }
// }

main div.container,
main div.container-fluid,
main div.container-sm,
main div.container-md,
main div.container-lg,
main div.container-xl {
    margin-bottom: 0;
}

#main-content:focus-visible {
    outline-color: var(--link-color);
}

// @media only screen and (max-width: 48em) {
//     .container [class^="col-md"]>div {
//         padding: 0 !important;
//     }
// }


/* headers and text */

h1 {
    font-size: 1.75rem !important;
    line-height: 2rem !important;
    margin-bottom: 1rem;
}

h2 {
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
}

h3,
h4 {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
}

h5,
h6,
p {
    font-size: 1rem;
    line-height: 1.5rem;
}

//global
body {
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    max-width: 1140px;
    margin: auto !important;
}

//general
a,
a:visited,
.btn-link {
    color: var(--link-color);
    text-decoration: none;
    font-weight: bold;

    svg {
        fill: var(--link-color)
    }

    &:hover {
        color: var(--link-color);
        font-style: underline;
    }
}

.alert-link {

    a:link,
    a:visited {
        color: #842029;

        &:hover {
            color: #842029;
            filter: brightness(80%);
        }

        &:active, &:focus {
          outline: none !important;
          box-shadow: none !important;
        }

        &:focus-visible {
            outline: 2px solid #000 !important;
            outline-offset: 2px !important;
            box-shadow: 0 0 0px 1px #fff !important;
        }
    }
}

.btn-primary,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled),
:not(:disabled).btn-primary
.btn-primary:visited {
    color: $white;
    background-color: var(--link-color) !important;
    border-color: var(--link-color) !important;
    border-radius: 0;
    margin-left: 10px;
    box-shadow: none;

    &:hover {
        background-color: var(--link-color) !important;
        border-color: var(--link-color) !important;
        filter: brightness(80%);
    }

    &:disabled,
    &.disabled {
        background-color: var(--link-color) !important;
        border-color: var(--link-color) !important;
        opacity: 0.65;
        border-radius: 0;
        cursor: not-allowed;

        &:hover {
            background-color: var(--link-color) !important;
            border-color: var(--link-color) !important;
            opacity: 0.65;
            cursor: not-allowed;
        }
    }
}

.btn-secondary,
.btn-secondary:not(:disabled),
:not(:disabled).btn-secondary {
    color: var(--link-color, #007FA7) !important;
    background: $white !important;
    border-color: var(--link-color, #007FA7) !important;
    border-radius: 0 !important;

    &:hover {
        background: $white !important;
        filter: brightness(80%);
    }

    &:disabled,
    &.disabled {
        border-radius: 0;
    }
}

.bg-primary {
    background-color: var(--link-color) !important;
}

//for A11y
a,
a:link,
a:visited,
button,
span,
select,
input,
li {
    &:active, &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &:focus-visible {
        outline: 2px solid #000 !important;
        outline-offset: 2px !important;
        box-shadow: 0 0 0px 1px #fff !important;
    }
}

li:focus-visible {
    border: 2px solid #000 !important;
    outline-offset: 2px !important;
    box-shadow: 0 0 0px 1px #fff !important;
}

//header//
.template-header {
    background-color: $white;
    color: $black;
    font-size: 16px;

    .user-links {
        display: none;
    }


    .navbar-toggle-icon {
        display: flex;
        justify-content: center;

        svg {
            fill: var(--link-color);
            align-self: center;
            outline: none;
        }
    }

    .branding {
        display: none;
    }

    .greeting {
        font-weight: normal;
    }

    .profile-link {
        color: var(--link-color);
        font-weight: bold;
        cursor: pointer;
        padding-top: 1px;
        padding-right: 6px;
        padding-bottom: 1.75px;
        padding-left: 6px;

        &:hover {
            text-decoration: underline;
            filter: brightness(80%);
        }
    }

}

@include media-breakpoint-up(md) {
    .template-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        margin: 1em 0 1em;

        .branding {
            padding: 0;
            margin: 0;
            display: inline;

            li {
                list-style: none;
                display: inline;
            }

            .client-logo {
                max-width: 160px;
                max-height: 100px;
                margin-right: 1em;
            }

            .wtw-logo {
                max-width: 230px;
                max-height: 100px;
            }
        }

        .user-links {
            display: block !important;
            text-align: right;

            .logout,
            .header-notifications {
                color: var(--link-color);
                font-weight: bold;
                padding-right: 0;

                position: relative;

                &:hover {
                    color: var(--link-color);
                    filter: brightness(80%);
                    text-decoration: underline;
                }
            }
        }
    }
}

//nav bar//

/*override transition for slideout instead of slide-down*/

.navbar {
    margin: 0.5rem 0 0.5rem;
    padding: 0 !important;
    position: fixed !important;
    width: 100%;
    height: 70px;
    left: 0;
    top: -10px;
    padding: 10px 15px !important;
    z-index: 50000;
    background-color: $white;
    border-bottom: solid 1px #ccc;

    .navbar-expand-md {
        .navbar-collapse {
            padding: 0.5em;
        }
    }

    .navbar-toggler {
        border: 0;
        padding: 0;

        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }

        .navbar-toggle-icon {

            &:focus,
            &:active {
                outline: none;
                box-shadow: none;
            }

            svg {
                fill: var(--link-color);
                height: 24px;
            }
        }
    }

    #languages {
        width: 230px;
        color: #000;
        padding-top: 0;
        margin-top: 0;
        border-radius: 0;
        background-color: $white;
    }

    .client-logo {
        max-width: 160px;
        max-height: 50px;
        height: 50px;
        margin: auto;
        display: flex;
        align-self: center;

        img {
            height: auto;
            width: auto;
        }
    }
}

#navbarSupportedContent {
    justify-content: space-between;

    .navbar-nav {
        margin: 1em 0 1em;

        li {
            a {
                display: flex;
                justify-content: space-between;
                padding: 1em;
                width: 100%;

                &.profile-link {
                    justify-content: left;
                }
            }
        }

        a.dropdown-item {
            color: var(--link-color);

            &:active,
            &:hover {
                background-color: var(--primary-color);
                color: $white;
            }

            a.dropdown-item {
                color: var(--link-color);
                font-weight: bold;

                &:active,
                &:hover {
                    background-color: white;
                    color: var(--link-color);
                }
            }
        }

        #navMenuBack,
        #languageMenuBack {
            justify-content: start !important;
            align-items: center;
        }

        #logout,
        #logout:visited {
            display: flex;
            text-align: center;
            justify-content: center;
            margin: 2em 1.5em;
            background-color: $white !important;
        }

        .submenu-header {
            padding: 15px;
            background-color: $software-gray-1;
            font-size: 1.2em;
            font-weight: 700;
            border-top: 1px solid #333 !important;
            border-bottom: 1px solid #ccc;
            width: 100%;
            pointer-events: none;
        }
    }
}

/*mobile only*/
@include media-breakpoint-down(md) {

    //overwrite mobile menu - outer menu slide in
    // main {
    //     margin-top: 70px
    // }

    .navbar-collapse {
        position: fixed;
        top: 68px;
        left: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        height: 100%;
    }

    .navbar-collapse.collapsing {
        height: 100%;
        left: -100%;
        transition: height 0s ease;
    }

    .navbar-collapse.show {
        height: 100%;
        left: 0;
        transition: left 300ms ease-in-out;
    }

    .navbar-toggler.collapsed~.navbar-collapse {
        transition: left 300ms ease-in-out;
    }

    #navbarSupportedContent {
        background-color: $white;
        padding: 0.5rem 0px;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 68px;
        z-index: 50000;
        left: -100%;

        &.show {
            left: 0;
            transition: left 300ms ease-in-out;
            overflow-y: scroll;
            padding-bottom: 50px;
        }

        //overwrite mobile menu - inner menu slide in
        #infoCenterSubMenu,
        #mobileLanguageSubMenu {
            position: fixed !important;
            top: 65px;
            width: 100%;
            left: 100%;
            height: 100%;
            border: 0;
            padding: 0;
            overflow-y: auto;
            transition: all 0.3s ease;
            display: block;
        }

        #infoCenterSubMenu.collapsing,
        #mobileLanguageSubMenu.collapsing {
            height: 100% !important;
            margin-left: 50%;
            left: 50%;
            transition: all 0.15s ease;
        }

        #infoCenterSubMenu.show,
        #mobileLanguageSubMenu.show {
            left: 0;
            overflow-y: scroll;
        }

        #languages {
            margin: 1em auto;
            display: flex;
        }

        .mobile-show {
            display: inline-block;

            &.flex {
                display: flex;
            }
        }
    }

    .navbar-nav {
        li {
            font-weight: bold;
            border: solid 1px $software-gray-3;
            border-bottom: 0;

            &:last-of-type {
                border-bottom: solid 1px $software-gray-3;
            }

            a {
                color: var(--link-color);

                &:visited {
                    color: var(--link-color);
                }

                &.dropdown-toggle::after {
                    display: none;
                }

                .icon svg {
                    fill: var(--link-color);
                    margin-right: 5px;
                }

                .arrow svg {
                    fill: var(--link-color);
                }
            }
        }

        a.dropdown-item {
            color: var(--link-color);
            font-weight: bold;

            &:active,
            &:hover {
                background-color: white;
                color: var(--link-color);
            }
        }
    }

    #navMenuBack {
        justify-content: start !important;
    }

    #logout,
    #logout:visited {
        display: flex;
        text-align: center;
        justify-content: center;
        margin: 2em 1.5em;
        background-color: $white !important;
    }

    .submenu-header {
        padding: 15px;
        background-color: $software-gray-1;
        font-size: 1.2em;
        font-weight: 700;
        border-top: 1px solid #333 !important;
        border-bottom: 1px solid #ccc;
        width: 100%;
        pointer-events: none;
    }
}

@include media-breakpoint-up(md) {
    .mobile-show {
        display: none !important;
    }

    .navbar {
        background-color: $software-gray-1;
        justify-content: space-between;
        position: static !important;
        height: 48px;
        border: 0;
        padding: 0;

        .client-logo {
            display: none;
        }

        #navbarSupportedContent {
            padding: 0;
            flex-direction: row;
            background: transparent;
            top: auto;
            position: static;

            .mobile-show {
                display: none;
            }

            .navbar-nav {
                margin: 0;
                padding: 0;
                border-top: 0;
                border-bottom: 0;

                li {
                    // padding: .5em 1.25em;
                    border: none;
                    height: 48px;
                    font-weight: 400;

                    a {
                        align-items: center;
                        padding: 0.75em 1em;
                    }

                    a.navlink {
                        color: $black;
                    }

                    &:hover {
                        background: $software-gray-3;
                    }

                    &.active {
                        a.navlink {
                            background: var(--primary-color);
                            color: $white;
                            padding: 0.75em 1em 1.25em;
                            font-weight: bold;
                            margin-bottom: -0.5em;
                            text-align: center;

                            &:hover {
                                background: var(--primary-color);
                                filter: brightness(80%);
                                color: $white;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    background: $white;

                    li {
                        padding: 0;
                    }
                }
            }
        }
    }
}

//end replace 3/25/ eb

//slick slider theme
$slick-loader-path: "./" !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active:1 !default;

/* Slider */

// .slick-list {
//     .slick-loading & {
//         background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
//     }
// }


/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 26px;
    width: 26px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:hover,
    &:focus {
        outline: none;
        background: transparent;
        color: transparent;

        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
}


/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 0px;
}

.slick-dots {
    position: absolute;
    bottom: 10px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;

            &:focus-visible {
                outline: 2px solid #000 !important;
                outline-offset: 2px !important;
                box-shadow: 0 0 0px 1px #fff !important;

                &:before {
                    opacity: 1;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: url($default-assets-path + "banner-imgs/icons8-360-degrees.svg");
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                color: white;
                opacity: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button:before {
            opacity: 1;
            content: url($default-assets-path + "banner-imgs/icons8-unchecked-checkbox.svg");
        }
    }
}

//custom styles
.slide-content-wrapper{
    width:100%;
    background-position: center;
    display:flex;
}

.slider {
    width: 100%;
    margin: 100px auto;
}

.slick-slide {
    margin: 0px 5px;
    transition: all ease-in-out .3s;
    opacity: .9;
    border: solid 1px $software-gray-3;

    img {
        width: 100%;
    }
}

.solo {
    .text-area {
        display: flex;
    }

    .content {
        align-self: center;
    }

    .slick-slide {
        width: 100% !important;
        margin: 0;
    }

    .slick-track {
        width: 100% !important;
    }
}

.slick-prev:before,
.slick-next:before {
    color: black;
}

.slick-active {
    opacity: 1;
}

.slick-current {
    opacity: 1;
}

#banner-slider {

    .slick-next,
    .slick-prev {
        height: 40px;
        width: 40px;
        background: #fff;
        border-radius: 50% !important;
        padding: 2px;
    }

    .slick-prev {
        z-index: 5;
    }

    .slide {
        background-repeat: no-repeat;
        // background-position: top;
        // background-size: 100%;
        background-position: center;
        background-size: cover;
        display: flex !important;

        &.one {
            background-image: url($default-assets-path + "banner-imgs/default.jpg");
        }

        &.two {
            background-image: url($default-assets-path + "banner-imgs/one.jpg");
        }

        &.three {
            background-image: url($default-assets-path + "banner-imgs/two.jpg");
        }

        &.four {
            background-image: url($default-assets-path + "banner-imgs/three.jpg");
        }
    }

    .text-area {
        width: 100%;
        background: #fff;
        padding: 0 10px;
        display: flex;

        p {
            margin-bottom: .5em;
        }

        .content {
            position: relative;
            z-index: 5;
            padding:0;

            .title {
                font-size: 28px !important;
                line-height: 30px !important;
                margin-bottom: 5px;
                margin-top: 5px;
            }

            .btn-link {
                color: var(--link-color);
                padding-left: 0;
            }

            .arrow svg {
                fill: var(--link-color);
                margin-left: .5em;
            }
        }
    }
}

.sliderWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 5px;
}

@include media-breakpoint-up(md) {
    .solo .content {
        margin-top: 0;
    }

    .sliderPlayPause {
        position: absolute;
        right: 15px;
        top: 5px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        z-index: 1;
        background: #fff;
        border-radius: 50%;
        background-image: url($default-assets-path + "banner-imgs/icons8-pause.svg");
        background-position: center center;
        background-repeat: no-repeat;

        &.play {
            background-image: url($default-assets-path + "banner-imgs/icons8-play.svg");
        }
    }

    .sliderWrapper {
        margin: 15px 0;
    }

    #banner-slider {
        .slick-slide {
            margin: 0px;
            border: 0;
            height: 100% !important;
        }

        .slick-prev {
            left: 15px;

            &:before {
                width: 44px;
                height: 44px;
                content: url($default-assets-path + "banner-imgs/icons8-back48.svg");
                position: relative;
                top: -5px;
                left: -5px;
            }

            &:focus {
              outline: none !important;
              box-shadow: none !important;
            }

            &:focus-visible {
                outline: 2px solid #000 !important;
                outline-offset: 2px !important;
                box-shadow: 0 0 0px 1px #fff !important;
            }
        }

        .slick-next {
            right: 15px;

            &:before {
                width: 24px;
                height: 24px;
                content: url($default-assets-path + "banner-imgs/icons8-forward48.svg");
                position: relative;
                top: -5px;
                left: -5px;
            }

            &:focus {
              outline: none !important;
              box-shadow: none !important;
            }

            &:focus-visible {
                outline: 2px solid #000 !important;
                outline-offset: 2px !important;
                box-shadow: 0 0 0px 1px #fff !important;
            }
        }

        .slide {
            height: 280px;

            .text-area {
                width: 40%;
                padding: 20px;
                position: relative;
                margin-top: 0;
                margin-left: 10%;
                overflow: hidden;
                display: flex;
                align-self: center;
                background: transparent;

                .content {
                    position: relative;
                    z-index: 5;

                    .title {
                        font-size: 32px !important;
                        line-height: 34px !important;
                    }
                }

                .bg {
                    background-color: #fff;
                    opacity: .9;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                }
            }
        }
    }
}

// image tiles //
.img-tile {
    border: solid 1px $software-gray-3;
    height: auto;

    .top-tile {
        display: none;
    }

    .lower-tile {
        height: 120px;
        position: relative;
        padding: 0 10px;
    }

    .widget-header {
        padding: 12px 0px;
        display: flex;
        align-items: center;

        h2 {
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: bold;
            text-align: left;
            color: $black;
            margin-bottom: 0;
        }

        .icon {
            svg {
                margin-right: 10px;
                fill: var(--primary-color);
            }
        }
    }

    .widget-button {
        font-weight: bold;
        position: absolute;
        right: 0;
        bottom: 12px;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-self: flex-end;

        .btn-link {
            font-size: 16px;
            line-height: 16px;
            color: var(--link-color);
            font-weight: bold;
            text-align: right;
            display: flex;
            align-items: center;
            align-self: flex-end;

            &.nopadding {
                padding-top: 0;
            }

            .arrow {
                svg {
                    margin-left: .5em;
                    fill: var(--link-color);
                }
            }

            &:hover {
                fill: var(--link-color);
                filter: brightness(80%);

                .arrow {
                    svg {
                        filter: brightness(80%);
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .img-tile {
        height: 460px;

        .top-tile {
            display: block;
            height: 228px;
            width: 100%;
            background-size: cover;

            background-position: center;
        }

        .lower-tile {
            background-color: $white;
            color: $black;
            height: 230px;
            width: 100%;
            text-align: center;
            position: relative;
            padding: 0 10px;
        }

        .widget-header {
            padding: 36px 0 0;
            display: block;

            h2 {
                font-size: 28px !important;
                line-height: 30px !important;
                font-weight: bold;
                text-align: center;
            }

            .icon {
                display: none;
            }
        }

        .widget-button {
            left: 0;
            margin: auto;
            bottom: 30px;

            .btn-link {
                align-self: center;
            }
        }
    }
}

//link lists//
// LINK LIST WIDGET TYPE SPECIFIC STYLES
.link-list {
    background-color: $white;
    border: solid 1px $software-gray-3;
    height: 100%;
    padding: 12px;
    height: auto;

    .widget-header h3 {
        text-align: center;
        font-size: 24px !important;
        line-height: 26px !important;
        font-weight: bold;
        padding: 24px 0;
    }

    .widget-icon {
        width: 48px;
        height: 48px;
        margin: auto;
        margin-bottom: .5em;
        display: flex;

        svg {
            fill: var(--primary-color);
            align-self: center;
        }
    }

    .widget-title {
        color: $black;
        font-weight: bold;
        font-size: 24px;
        overflow-wrap: break-word;
    }

    .list-group-item {
        padding: 12px;
        border-top: 0;
        border-bottom: solid 1px $software-gray-3;
        border-left: 0;
        border-right: 0;
        border-radius: 0 !important;

        &:last-child {
            border: 0;
        }

        a,
        a:visited {
            color: var(--link-color);
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                color: var(--link-color);
                filter: brightness(80%);
                cursor: pointer;
                text-decoration: underline;

                svg {
                    filter: brightness(80%);
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .link-list {
        height: auto;
    }
}

.widget-link-icon {
    svg {
        fill: var(--link-color);
        margin-left: .5em;
    }
}

//footer
#common-footer-element {
    width: 100%;
    padding: 15px;
}

#footer {
    width: 100%;
    margin: auto;
    margin-top: 36px;
    border-top: 1px solid #000;

    .img-fluid {
        width: 100%;
        max-width: 100%;
        height: 50px;
        margin-top: 15px;

        &.corporate-logo {
            width: 62px !important;
            height: 20px !important;
        }
    }

    @media screen and (max-width: 768px) {
        .img-fluid {
            margin-bottom: 15px;
        }
    }
}

.legal-link {
    display: block;
    color: $black;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $black;
        font-weight: bold;
    }

    &:visited {
        text-decoration: none;
        color: $black;
    }
}

.legal-notices {
    .col-md-6 {
        padding: 0;
        margin-top: 0;
    }

    a {
        margin-bottom: .5em;
    }
}

@include media-breakpoint-up(md) {
    #footer-content {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .legal-notices {
            width: 50%;
            margin-left: auto;
            display: flex;
            margin-top: 14px;

            .col-md-6 {
                padding-left: 15px;
            }
        }
    }

    #copyright {
        margin-top: -2em;
    }
}

//preview
.Reset-Button-margin {
    margin-top: 30px;
}

.date-pickerDiv {
    width: 232px;
}

#preview-bar {
    justify-content: space-between;
    background-color: $black;
    color: $software-gray-1;
    align-items: center;
    padding: .5em 0;
    margin-bottom: 1.25em;

    .badge-primary {
        color: $software-gray-3;
        background-color: transparent;
        border-radius: 0;
        padding-left: 0;
        border-bottom: solid 1px var(--link-color);
        width: 100%;
        text-align: left;
    }

    .client-name {
        display: block;
        line-height: 1em;
        padding: .5em 0;
    }

    .ng-select .ng-select-container {
        border-radius: 0;
    }

    button {
        border: 0;
    }

    .roles {
        width: 100%;

        #roles-selector {
            width: 95%;
        }
    }

    .datepicker {

        //width: 260px;
        .ngb-dp-arrow-btn {
            color: var(--link-color);

            &:hover {
                color: var(--link-color);
            }
        }

        div.btn-light.bg-primary.text-white {
            background-color: var(--link-color) !important;
            outline: none !important;
            border: 0 !important;
        }

        input {
            background: $white;
        }

        .ngb-dp-weekday {
            color: var(--link-color) !important;
        }

        .btn-sm {
            height: 38px;
            padding-top: 6px;
            border-radius: 0;
            border: 1px solid $software-gray-4;
            border-left: 0;

            .calendar {
                width: 24px;
                height: 24px;
                display: inline-block;
                background-image: url($default-assets-path + "icons/icons8-calendar.svg");
                background-repeat: no-repeat;
            }

            .bg-primary {
                background-color: var(--link-color) !important;
            }

            div.btn-light.bg-primary.text-white {
                background-color: var(--link-color) !important;
            }
        }
    }

    .apply {
        margin-right: 1rem;
    }
}

//contact us form//
.contact-us-form {
    .form-group {
        text-align: left;
    }
}


/* You can add global styles to this file, and also import other style files */

.form-container {
    margin-top: 1em;
}

.form-control {
    border-radius: 0 !important;
}


/*toasts*/

.toast-container {
    display: block;
    align-items: center;
    color: white;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 50000;
    width: 420px;
    max-width: 100%;

    .toast-header {
        border: 0;
    }

    .toast-body {
        display: flex;
        padding: 0 1rem 1rem 1rem;
        padding-top: 0;
    }

    h2 {
        color: white;
    }

    p {
        color: $software-gray-9;
    }

    .icon {
        width: 30px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        background-color: none;
        margin: 0 1em 0 0;
    }

    &.error {
        background: #fff;
        border: 1px solid $software-danger;

        .icon {
            background-image: url($default-assets-path + "icons/icons8-error.svg");
        }
    }

    &.success {
        background: #fff;
        border: 1px solid $software-success;

        .icon {
            background-image: url($default-assets-path + "icons/icons8-ok.svg");
        }

        .close {
            border: 0;
        }
    }
}

form-control:hover {
    color: var(--link-color) !important;
}

.attachment-container {
    margin: 1em 0 2em;
}

.error-container {
    border: solid 1px $software-danger;
    padding: 10px;
    margin: 10px 0;
}

.error-container .error-message {
    margin: 5px 0;
    color: $software-danger;
    font-weight: bold;
}

.disclaimer {
    margin-top: 2em;
    text-align: left;
}


/* Overlay */

#overlay {
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;
}

.spinner {
    margin: 0 auto;
    height: 64px;
    width: 64px;
    animation: rotate 0.8s infinite linear;
    border: 5px solid var(--link-color);
    border-right-color: transparent;
    border-radius: 50%;
}

//accordion - info ctr
#accordion {
    margin-top: 1em;

    //panel text highlight
    .panel-body {
        padding: 10px 0 20px;

        .marker-yellow {
            background-color: #fdfd77;
        }

        .marker-green {
            background-color: #62f962;
        }

        .marker-pink {
            background-color: #fc7899;
        }

        .marker-blue {
            background-color: #72ccfd;
        }

        .pen-red {
            color: #e71313;
            background-color: transparent;
        }

        .pen-green {
            color: #128a00;
            background-color: transparent;
        }

        //panel text sizes
        .text-huge {
            font-size: 1.8em;
        }

        .text-big {
            font-size: 1.4em;
        }

        .text-default {
            font-size: 1em;
        }

        .text-small {
            font-size: .85em;
        }

        .text-tiny {
            font-size: .7em;
        }

        p {
            margin-bottom: 0;
        }

        a:link,
        a:visited {
            color: var(--link-color);

            &:hover {
                color: var(--link-color);
                filter: brightness(80%);
            }
        }

        //panel tables
        .table {
            display: table;
            margin: 1em auto;

            table {
                border-collapse: collapse;
                border-spacing: 0;
                width: 100%;
                height: 100%;
                border: 1px double #b3b3b3;

                td,
                th {
                    min-width: 2em;
                    padding: .4em;
                    border: 1px solid #bfbfbf;
                }
            }
        }

        //panel images
        .image {
            display: table;
            clear: both;
            text-align: center;
            margin: 1em auto;

            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                min-width: 50px;
            }
        }

        .image-style-align-right {
            float: right;
            margin-left: 20px;
        }

        .image-style-align-left {
            float: left;
            margin-right: 20px;
        }

        .image>figcaption {
            display: table-caption;
            caption-side: bottom;
            word-break: break-word;
            color: #333;
            background-color: $software-gray-1;
            padding: .6em;
            font-size: .75em;
            outline-offset: -1px;
            margin: 1em auto;
        }

        .ck.ck-reset_all,
        .ck.ck-reset_all * {
            border-collapse: collapse;
            font: normal 1em/1.25em;
            color: $black;
            text-align: left;
            white-space: nowrap;
            cursor: auto;
            float: none;
        }
    }

    //accordion framework
    .accordion {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $software-gray-1;
        font-weight: bold;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        font-size: 16px;
        transition: 0.4s;
        border-top: solid 2px $white;
        border-bottom: solid 2px $white;
        color: var(--link-color);

        h2 {
            font-size: 18px !important;
            line-height: 22px !important;
            font-weight: bold;
            margin-bottom: 0;
        }
        
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }

        &:focus-visible {
            outline: 2px solid #000 !important;
            outline-offset: 2px !important;
            box-shadow: 0 0 0px 1px #fff !important;
        }

        p {
            margin-block-end: 0 !important;
            margin-bottom: 0 !important;
        }

        .minus {
            display: none;
        }

        .plus {
            display: block;
        }

        svg {
            fill: var(--link-color);
        }
    }

    .active {
        background-color: $software-gray-1;

        .minus {
            display: block;
        }

        .plus {
            display: none;
        }
    }

    .panel {
        padding: 0 18px;
        background-color: $white;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
}

#single-spa-application\:find-out-more, 
#single-spa-application\:faqs,
#single-spa-application\:definitions{
    .sidebar {
        margin-top: 50px;
        padding-left: 0px;
        width: 100% !important;
        margin-left:0;

        .vertical-menu {
            border: solid 1px $software-gray-3 !important;
            padding: 20px;

            a {
                // background-color: #eee;
                color: var(--link-color);
                font-weight: bold;
                display: block;
                padding: 12px;
                text-decoration: none;
                border-bottom: solid 1px $software-gray-3;
                padding: 10px 0;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    color: var(--link-color);
                    filter: brightness(80%);
                    text-decoration: underline;
                }
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .sidebar {
        padding-left:15px;
    }
}

@include media-breakpoint-down(lg) {
    #accordion {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}


/* user profile */

#userProfile {
    .row {
        .line {
            margin: 0 15px 24px;
            border-bottom: solid 1px $software-gray-3;
        }
    }
}

//actions menu
#actionsMenu {
    height: auto;
    background-color: var(--primary-color);
    transition: background-color 0.3s ease-in;
    transition-delay: 0.3s;
   
    #menuCloseBtn {
        background-color: transparent;
        border: 0;
        background-image: url($default-assets-path + "close-lg-white.svg");
        background-repeat: no-repeat;
        background-position: center;
        height: 24px;
        width: 24px;
        background-size: 100%;
        margin: 20px 0;
        position: absolute;
        right: 20px;
        top: 8px;
    }
    #menuBtn,
    #menuBtn:visited {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
        font-size: 1.5em;
        border: 3px solid #FFFF !important;
        border-radius: 1.75em !important;
        padding: 0.3em 1.4em;
        margin: 1.2em auto;
        color: #fff;
    }
    &.open {
        background-color: var(--primary-color);
        transition: all 0.3s ease;
        #menuBtn,
        #menuBtn:hover {
            background: transparent;
            border: none;
            filter: brightness(100%);
            cursor: auto;
            margin: 1.2em auto 0.5em;
        }
    }
    .menu-opened {
        transform: rotate(180deg);
    }
    .menu-closed {
        transform: none;
    }
}

@include media-breakpoint-down(lg) {
    #actionsMenu {
        #actionsMenuLinks {
            margin: 1em;
        }
        #menuCloseBtn {
            right: 30px;
        }
    }
}


//notification center
.notification-center {


    ul,
    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .replies {
        //max-width: 550px;
        margin: auto;

        li {
            margin: 10px 0;
        }

        .card-body {
            border-top: solid 4px $software-gray-4;
        }

        p {
            color: $software-gray-7 !important;
        }
    }

    .card {
        border-radius: 0;
        border: solid 1px $software-gray-4;
        border-top: 0;

        .card-body {
            .status {
                position: relative;
            }

            &.is-new {
                p {
                    font-weight: bold;
                }
            }
        }

        .is-new {
            .card-title {
                font-weight: bold;
            }
        }

        //card in reading pane
        &.selected {
            border: 0;
            margin: 30px 0 50px;

            h2 {
                border-bottom: solid 1px $software-gray-4;
                padding-bottom: 5px;
            }

            .card-body {
                padding: 0;
            }
        }

        //selected item in list
        &.is-selected {
            .card-body {
                border-left: solid 4px var(--link-color);
            }
        }

        //unread dot in list of items
        .unread-dot {
            position: absolute;
            left: -10px;
            top: 35px;
            fill: var(--primary-color);

            &.in-header {
                top: 0px;
                left: 0px;
            }
        }

        .header-notifications {
            .unread-dot.in-header {
                position: absolute;
                right: 0px;
                top: 0px;
                fill: var(--primary-color);
            }
        }


        .card-title {
            font-size: 16px;
            line-height: 18px !important;
            font-weight: normal;
            color: $black;
        }

        .card-date,
        .sender {
            color: $software-gray-7;
            font-size: 12px;
            line-height: 12px;
            font-weight: normal;
            margin-bottom: 0;
        }

        .card-date {
            text-align: right;
        }

        .sender {
            margin-right: 10px;
        }

        .card-text {
            color: $black;
            font-size: 1em;
            font-weight: normal;
        }
    }

    .active {
        background-color: $white;
        padding: 20px;
        display: flex;
        justify-content: end;

        .selected {
            border: 0px !important;
            margin-bottom: 50px;
            border: 0px;


            .card-body {
                padding: 0px;
            }

            h2 {
                border-bottom: solid 1px $software-gray-7;
            }
        }
    }
}

#notificationsCenter-link,
#notificationsCenter-link2 {
    position: relative;

    .unread-dot {
        position: absolute;
        right: -2px;
        top: 2px;
        fill: var(--primary-color);
    }
}

@include media-breakpoint-up(md) {
    .notification-center {
        .action-required {
            font-size: 12px;
            //font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            color: #C14711;
            display: inline-block;
            line-height: 1;
            text-align: center;
            //white-space: nowrap;
            vertical-align: baseline;
            border: 1px solid #C14711;
            border-radius: 10rem;
            margin-bottom: 5px;
            padding: 4px 8px 2px
        }

        &.reading-pane {
            background: $white;
            justify-content: center;
            //max-width:550px;
            // margin:auto;
            padding: 10px 0 10px 20px;
        }
    }

    .__detail {
        background-color: $white;
    }
}

.mobile-back {
    background-color: $software-gray-1;
    padding: 10px 15px;
    margin: 0 -15px;
    border-bottom: solid 1px #ccc;

    a {
        margin: 0
    }

}

.flip-h {
    transform: rotate(180deg);
}

.sr-only {
    position: absolute;
    left: -9999999px;
}

// For Daynamic Tables in Welbeing Article Deailes

.cust-body-content {
    display: table !important;
    margin: 1em auto !important;

    table {
        border-collapse: collapse !important;
        border-spacing: 0 !important;
        width: 100% !important;
        height: 100% !important;
        border: 1px double #b3b3b3 !important;

        td,
        th {
            min-width: 2em !important;
            padding: .4em !important;
            border: 1px solid #bfbfbf !important;
        }

    }

    //For setting image width in article detail
    img {
        max-width: 100% !important;
    }

}
// OE Banner
@media screen and (max-width: 667px) {

    .countdown{
        justify-content: flex-start !important;
        flex-direction: column !important;
        align-items: flex-start !important;
       
    }
    hr{
        display: none !important;
    }
}
@media screen and (min-width: 768px) {
    #banner-slider .slide .text-area {
        width: auto !important;
        max-width: 100% !important;
        min-width: 50% !important;
        margin-right: 10% !important;
    }
}
.count-down-timer-inner {
  display: flex;
  align-items: center;
  color: var(--link-color, #007FA7) !important;
}
.enroll-button {
  background-color: var(--link-color, #007FA7)!important;
  margin-top: 6px !important;
  padding: 5px 8px;
  color: white !important;
  border: none !important;
  text-align: left !important;
}

// bootstrap 5 adjustment. 
// overrides width:100% that breaks some of bootstrap 4 compatible layout.
.row>* {
    width: auto; 
}