#accept-recommended-btn-handler {
    display: inline-block !important;
}

#onetrust-pc-sdk {
    min-height: 80%;
}

#ot-pc-title, #ot-category-title {
    font-size: 1em !important;
}

.ot-cat-header {
    font-size: .875em !important;
}

@media only screen and (max-width:600px)
{
    button#accept-recommended-btn-handler
    {
        width: 40% !important;
        font-size: 0.75rem !important;
    }

    #onetrust-pc-sdk button
    {
        padding-left: 30px !important;
    }

    #ot-pc-content
    {
        margin-left: 15px !important;
        padding-right: 5px !important;
    }

    #onetrust-pc-sdk .ot-pc-scrollbar::-webkit-scrollbar {
        width: 8px !important;
    }
}